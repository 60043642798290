import { pluralize } from '../pluralize'

/**
 * Module:  Video Playlist
 * @desc    YouTube-like playlist controller UI
 */
const defaults = {
  debug: false,
};

const CLASS_PLAYLIST_LANDING_PAGE = 'playlist-landing-page';
const SINGLE_VIDEO = 'single-video';
const ID_VIDEO_PLAYLIST = 'video-playlist';
const ID_VIDEO_FRAME = 'vid_frame';
const CLASS_TITLE_UNDER_VIDEO = 'js-title-under-video';
const CLASS_PLAYLIST_LINK = 'video-playlist__link';
const ID_PLAYLIST_UNDER_VIDEO = 'video-playlist-under-video';
const CLASS_PLAYLIST_TITLE_UNDER_VIDEO = 'js-playlist-title-under-video';
const CLASS_DURATION_UNDER_VIDEO = 'js-duration-under-video';
const VIEW_COUNT_UNDER_VIDEO = 'js-view-count-under-video';
const DOWNLOAD_VIDEO_LINK = 'video-download-link';

// eslint-disable-next-line valid-jsdoc
/**
 * Primary Function
 * @param options options Optional properties to override defaults
 * @return
 */
class VideoPlaylist {
  constructor(options) {
    this._options = { ...defaults, ...options };
    this._singleVideo = document.querySelector(`.${SINGLE_VIDEO}`);
    this._playlistLandingPage = document.querySelector(`.${CLASS_PLAYLIST_LANDING_PAGE}`);
    this._titleUnderVideo = document.querySelector(`.${CLASS_TITLE_UNDER_VIDEO}`);
    this._playlistUnderVideo = document.querySelector(`#${ID_PLAYLIST_UNDER_VIDEO}`);
    this._playlistTitleUnderVideo = document.querySelector(`.${CLASS_PLAYLIST_TITLE_UNDER_VIDEO}`);
    this._durationUnderVideo = document.querySelector(`.${CLASS_DURATION_UNDER_VIDEO}`);
    this._viewCountUnderVideo = document.querySelector(`.${VIEW_COUNT_UNDER_VIDEO}`);
    this._playlist = document.getElementById(ID_VIDEO_PLAYLIST);
    this._frame = document.getElementById(ID_VIDEO_FRAME);
    this._playerLinks = document.querySelectorAll(`.${CLASS_PLAYLIST_LINK}`);
    this._downloadVideoLink = document.getElementById(DOWNLOAD_VIDEO_LINK);
    this.init();
  }

  init() {
    if (!this._playlist || !this._frame || !document.querySelector('.active')) {
      return;
    }
    // eslint-disable-next-line no-unused-expressions
    this._options.debug && console.log('Video playlist detected!');
    /*
    $('.vid-item').each(function(index) {
      $(this).on('click', function(){
        var current_index = index+1;
        $('.vid-item .thumb').removeClass('active');
        $('.vid-item:nth-child('+current_index+') .thumb').addClass('active');
      });
    });
    */
    this._playerLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        this.onClickLink(link);
      });
    });

    if (this._playlistLandingPage == null){
      this._playerLinks[0].classList.add('active');
    }

    this.scrollToActive()
  }

  scrollToActive() {
    let activeVideo = document.querySelector('.active')
    let playlistContainer = document.querySelector('.video-playlist__list')
    let position = activeVideo.getBoundingClientRect().bottom - playlistContainer.getBoundingClientRect().bottom
    if (position > 0) {
      playlistContainer.scrollTo({
        top: position,
        behavior: "smooth"
      });
    }
  }

  onClickLink = (link) => {
    this._playerLinks.forEach((l) => {
      l.classList.remove('active');
    });
    link.classList.add('active');
    this._frame.src = link.dataset.videoUrl;
    this._frame.poster = link.dataset.videoThumbnail;
    this._durationUnderVideo.innerHTML = link.dataset.videoLength;
    this._viewCountUnderVideo.innerHTML = `${link.dataset.videoViews} ${pluralize('view', link.dataset.videoViews)}`;
    this._downloadVideoLink.href = `${link.dataset.videoDownloadLink}`;

    if (this._titleUnderVideo != null){
      this._titleUnderVideo.innerHTML = link.dataset.videoTitle;
      this._playlistTitleUnderVideo.innerHTML = link.dataset.videoPlaylistTitle;
      this._durationUnderVideo.innerHTML = link.dataset.videoLength;

      if (this._playlistTitleUnderVideo.innerHTML == "undefined"){
        this._playlistUnderVideo.classList.remove('d-inline-block');
        this._playlistUnderVideo.classList.add('d-none');
      }
      else {
        this._playlistUnderVideo.classList.remove('d-none');
        this._playlistUnderVideo.classList.add('d-inline-block');
      }
    }

    if (this._playlistLandingPage != null && this._singleVideo == null){
      window.history.replaceState({}, 'TAK.gov', `/video_playlists/${link.dataset.videoPlaylistSlug}?video=${link.dataset.videoUuid}`);
    }
  };
}

/**
 * Public API
 */
export default new VideoPlaylist();
