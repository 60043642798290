document.addEventListener("DOMContentLoaded", function(event) {
  $(".video-jump").on('click', function(e) {
    e.preventDefault();
    var element = $(e.target);
    var seconds = element.data('seconds');
    var video = document.getElementById(element.data('video-element-id'));
    if (seconds !== null && video !== null) {
      video.currentTime = seconds;
      video.play();
    }
  });
});