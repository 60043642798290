// Default urls of embedded videos
const youtubeEmbed = "https://www.youtube.com/embed/";
const vimeoEmbed = "https://player.vimeo.com/video/";

document.addEventListener("DOMContentLoaded", function (event) {
    var wysiwygDisplayElements = document.getElementsByClassName("wysiwyg-display");
    if (wysiwygDisplayElements.length == 0) return;

    // Selects all of the elements with tag <oembed>
    for (wysiwygElement of wysiwygDisplayElements){
        var oembedElements = wysiwygElement.getElementsByTagName("oembed");
        // Loop through the <oembed> elements on the page, and replace each one with an <ifram>
        for (var i = oembedElements.length - 1; i >= 0; i--) {
            replaceOEmbed(oembedElements[oembedElements.length - 1]);
        }
    }
});

// Returns video's host site from the url
function getVideoHost(url) {
    var link = new URL(url);
    return link.hostname;
}

function replaceOEmbed(oembedElement) {
    var embedUrl;
    var lastIndex;

    var videoUrl = oembedElement.getAttribute("url");
    var videoHost;

    // If the videoUrl is an mp4 file, then instead of an iframe element,
    // a video element is created
    if (videoUrl.endsWith("mp4")){
        var videoElement = document.createElement("video");
        videoElement.setAttribute("controls", "");

        var sourceElement = document.createElement("source");
        sourceElement.setAttribute("src", videoUrl);
        sourceElement.setAttribute("type", "video/mp4");

        videoElement.appendChild(sourceElement);

        oembedElement.parentNode.replaceChild(videoElement, oembedElement);
        return;
    }

    try {
        videoHost = getVideoHost(videoUrl);
    }
    catch (error) {
        var badUrlDiv = document.createElement("div");
        badUrlDiv.innerHTML = "Invalid URL entered";
        oembedElement.parentNode.replaceChild(badUrlDiv, oembedElement);
        return;
    }

    // Sets 'platform' and 'lastIndex' variables
    // based on the platform that <oembed>'s url is based on
    switch (videoHost) {
        case "youtu.be":
            embedUrl = youtubeEmbed;
            lastIndex = '/';
            break;
        case "www.youtube.com":
            embedUrl = youtubeEmbed;
            lastIndex = '=';
            break;
        case "vimeo.com":
            embedUrl = vimeoEmbed;
            lastIndex = '/';
            break;
        default:
            var unknownDiv = document.createElement("div");
            unknownDiv.innerHTML = "Unknown media type";
            oembedElement.parentNode.replaceChild(unknownDiv, oembedElement);
            return;
    }

    // Gets key of the video at the end of the URL
    var videoKey = videoUrl.substring(videoUrl.lastIndexOf(lastIndex) + 1);

    // Beginning of full embed URL depends on the platform
    var fullEmbed = embedUrl + videoKey;

    // Creates <iframe> element, and sets all the attributes
    var iframeElement = document.createElement("iframe");
    iframeElement.setAttribute("width", "560");
    iframeElement.setAttribute("height", "315");
    iframeElement.setAttribute("src", fullEmbed);
    iframeElement.setAttribute("frameborder", "0");
    iframeElement.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture");
    iframeElement.setAttribute("allowFullscreen", "true");

    // Finally replaces <oembed> element with the newly created <iframe> element
    oembedElement.parentNode.replaceChild(iframeElement, oembedElement);
}