// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "@fortawesome/fontawesome-free/js/all";
import "../stylesheets/application"

require("packs/course_video")
require("packs/oembed")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const imgs = require.context('../img', true)
const imgPath = (name) => imgs(name, true)

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

const media = require.context('../media', true)
const mediaPath = (name) => media(name, true)

require('datatables.net-bs4')

import $ from 'jquery';
global.$ = jQuery;

import Alert     from 'bootstrap/js/src/alert';
import Button    from 'bootstrap/js/src/button';
import Carousel  from 'bootstrap/js/src/carousel';
import Collapse  from 'bootstrap/js/src/collapse';
import Modal     from 'bootstrap/js/src/modal';
import Popover   from 'bootstrap/js/src/popover';
import ScrollSpy from 'bootstrap/js/src/scrollspy';
import Tab       from 'bootstrap/js/src/tab';
import Tooltip   from 'bootstrap/js/src/tooltip';
// import Toast  from 'bootstrap/js/src/toast';
import Dropdown  from 'bootstrap/js/src/dropdown';

// customizations
import DropdownFilter from './customs/dropdown-filter';
import FacetDismiss from './customs/facet-dismiss';
import FormValidation, { CLASSNAME_NEEDS_VALIDATION } from './customs/form-validation';
import MultiLayeredForms from './customs/multi-layered-forms';
import FileUpload from './customs/file-upload';
import PasswordValidator from './customs/password-validator';
import BreadcrumbExpander from './customs/breadcrumb-expander';
import PasswordExposer from './customs/password-exposer';
import DuffleManager from './customs/duffle-manager';
import LoadMoreItemsButton, { ID_LOAD_MORE_BTN } from './customs/load-more-items';
import MarqueeVideoAutoplay from './customs/marquee-video-autoplay';
import VideoPlaylist from './customs/video-playlist';
import EventRegisterForm from './customs/event-register-form';
import VoteButton, { CLASS_VOTE_CONTAINER } from './customs/vote-button';
import ProductVersionSelect from './customs/product-version-select';

import {filesize} from "filesize";
window.filesize = filesize;

// auto init bootstrap features
[...document.querySelectorAll('[data-toggle="tooltip"]')].map((tooltip) => new Tooltip(tooltip));
[...document.querySelectorAll('[data-toggle="popover"]')].map((popover) => new Popover(popover));
// [...document.querySelectorAll('.toast')].map((toast) => new Toast(toast));

// custom initializers
[...document.querySelectorAll('.accordion-select .accordion-item')].map(
  (dropdown) => new DropdownFilter(dropdown)
);
[...document.querySelectorAll('.facet[data-dismissible="true"]')].map(
  (facet) => new FacetDismiss(facet)
);

[...document.querySelectorAll(`#${ID_LOAD_MORE_BTN}`)].map((btn) => new LoadMoreItemsButton(btn));

[...document.querySelectorAll(`.${CLASS_VOTE_CONTAINER}`)].map((btn) => new VoteButton(btn));
[...document.querySelectorAll(`.${CLASSNAME_NEEDS_VALIDATION}`)].map(
  (form) => new FormValidation(form)
);

export {
  Alert,
  Button,
  Carousel,
  Collapse,
  Modal,
  Popover,
  ScrollSpy,
  Tab,
  Tooltip,
  Dropdown,
  DropdownFilter,
  FormValidation,
  MultiLayeredForms,
  FileUpload,
  PasswordValidator,
  BreadcrumbExpander,
  PasswordExposer,
  FacetDismiss,
  DuffleManager,
  LoadMoreItemsButton,
  MarqueeVideoAutoplay,
  VideoPlaylist,
  EventRegisterForm,
  VoteButton,
  ProductVersionSelect,
};