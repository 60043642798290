/**
 * Module:  Product Version Select
 * @desc    Controls logic for syncing dropdowns on product detail page
 */
const defaults = {
  debug: false,
};

export default class ProductVersionSelect {
  constructor(elemIds, onChangeCallback, options) {
    this._onChangeCallback = onChangeCallback;
    this._options = { ...defaults, ...options };
    this._dropdown = document.getElementById(elemIds.dropdownContainerId);
    this._input = document.getElementById(elemIds.mainSelectorId);
    this._items = document.querySelectorAll(`#${elemIds.dropdownContainerId} a.dropdown-item`);
    this._button = document.querySelector(`#${elemIds.dropdownContainerId} button[data-bs-toggle="dropdown"]`);
    this.init();
  }

  init() {
    if (!this._dropdown || !this._input || !this._button) {
      return;
    }

    this._items.forEach((item) => {
      item.addEventListener('click', (e) => {
        const ver = e.target.dataset.value;
        const label = e.target.innerText;
        this._input.value = ver;
        this._button.innerText = label;

        this._onChangeCallback(ver);
      });
    });

    this._input.addEventListener('input', (e) => {
      this._button.innerText = e.target.selectedOptions[0].innerText.trim();
    });

    this._button.innerText = this._input.selectedOptions[0].innerText.trim();
  }
}

window.ProductVersionSelect = ProductVersionSelect;